import React from "react";

import Layout from "../components/Layout";

import { Link } from "gatsby";

const NotFoundPage = () => {
  return (
    <Layout>
      <div
        style={{
          backgroundImage:
            "linear-gradient(90deg, rgba(0,34,82) 0%, rgba(0,106,255) 100%), radial-gradient(circle, rgba(255,255,255,0.5) 0%, rgba(0,0,0,0.5) 100%)",
          backgroundBlendMode: "multiply",
        }}
      >
        <div class="px-20p sm:px-10">
          <div class="max-w-900p mx-auto text-white py-35p sm:py-60p">
            <h1 class="font-bold text-15152 sm:text-24242">
              Diese Seite existiert nicht
            </h1>
          </div>
        </div>
      </div>
      <div class="py-50p sm:py-80p px-20p sm:px-10">
        <div class="max-w-900p mx-auto space-y-35p sm:space-y-60p">
          <Link
            class="text-12121 sm:text-15152 font-medium text-lightblue transform hover:scale-150"
            to="/"
          >
            Zurück zum Start
          </Link>
        </div>
      </div>
    </Layout>
  );
};

export default NotFoundPage;
